import { GatsbySeo } from 'gatsby-plugin-next-seo'

const OrderConfirmationRecovery = () => {
  return (
    <>
      <GatsbySeo title="Order Confirmation" />

      <section className="section is-small">
        <div className="container is-max-desktop">
          <h1 className="title has-text-midnightBlue is-size-4-mobile">Order Confirmation</h1>

          <p className="subtitle has-text-midnightBlue is-size-6-mobile">
            Thank you for placing your order for a recovery certificate. Our team will process your information and
            email your certificate within the next 6 working hours.
          </p>
          <p>
            Our working hours for recovery certificates are from 9am to 5pm, Monday to Sunday (including public
            holidays).
          </p>
        </div>
      </section>
    </>
  )
}

export default OrderConfirmationRecovery
